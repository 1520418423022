import React from 'react';
import SearchComponent from './SearchComponent';

function App() {
  return (
    <div className="App">
      <SearchComponent />
      {/* Outros componentes ou conteúdos, se necessário */}
    </div>
  );
}

export default App;

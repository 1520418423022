import React, { useState } from 'react';
import axios from 'axios';
import './main.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState("");
  const [showingBula, setShowingBula] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [fontSize, setFontSize] = useState(14); // tamanho inicial da fonte

   const increaseFontSize = () => setFontSize(prev => prev + 2);
   const decreaseFontSize = () => setFontSize(prev => prev - 2);

  const fetchPageContent = async (url) => {
    try {
      const response = await axios.get(url);
      setPageContent(response.data);
    } catch (error) {
      console.error("Erro ao buscar a página:", error);
    }
  };



  const handleSearch = async (event) => {
  event.preventDefault();
  const cleanedSearchTerm = searchTerm.trim();

  // Verificar se cleanedSearchTerm tem menos de 3 caracteres
  if (cleanedSearchTerm.length < 3) {
      setShowAlert(true);
      return;
  }

  setShowAlert(false);
  setLoading(true);
  setShowingBula(false); // Redefinindo o estado para mostrar os resultados da pesquisa


    const apiUrl = `https://pricing.qualidoc.com.br/v3/api/api/v2/products/getBulas?searchTerm=${searchTerm}`;
    const headers = {
      'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJUSEVfSVNTVUVSIiwiYXVkIjoiVEhFX0FVRElFTkNFIiwiaWF0IjoxNjQ0ODU5NTkyLCJuYmYiOjE2NDQ4NTk1OTcsImV4cCI6MTcwNzk3NDc5MiwiZGF0YSI6eyJpZCI6IjEiLCJmaXJzdG5hbWUiOiJFZHVhcmRvIiwibGFzdG5hbWUiOiJOZXZlcyIsImVtYWlsIjoiZWR1YXJkby5uZXZlc0BxdWFsaWRvYy5jb20uYnIifX0.M8Hdt06hYaNyYhaxpnKcNWlU4wp6acGuQfRxbkhTXQk',
      'Content-Type': 'application/json'
    };

    try {
       const response = await axios.get(apiUrl, { headers });
       setResults(Array.isArray(response.data) ? response.data : []);
       setLoading(false);
     } catch (error) {
       console.error("Erro ao buscar dados:", error);
       setLoading(false);
     }
   };



   return (
     <div className="s004">


       <form onSubmit={handleSearch}>
         <fieldset>
 </fieldset>
           <input type="text" className="textbox" placeholder="Buscar Medicamento"
           value={searchTerm}
           onChange={e => setSearchTerm(e.target.value)}
           />
           <input title="Search" value="" type="submit" class="button"/>



       </form>
       {showAlert && (
         <div className="alert alert-secondary" role="alert">
           Digite pelo menos 3 letras.
         </div>
       )}
       {loading && <p>Buscando...</p>}

       {showingBula ? (
   <>
     <div className="results-button">

       <i
         className="fa fa-minus"
         onClick={decreaseFontSize}
         style={{ cursor: 'pointer', marginRight: '10px', fontSize: '20px' }}
       ></i>
       <i
         className="fa fa-plus"
         onClick={increaseFontSize}
         style={{ cursor: 'pointer', fontSize: '20px' }}
       ></i>
     </div>

     <div className="results-container" style={{ fontSize: `${fontSize}px` }}>
       <div
         dangerouslySetInnerHTML={{ __html: pageContent }}
         style={{
           fontSize: `${fontSize}px`,
           position: 'relative',
           marginTop: '10px',
           marginRight: '20px',
           marginBottom: '10px',
           marginLeft: '20px'
         }}
       ></div>
     </div>
   </>
       ) : (
         <div className="results-container">


           <ul className="results-list">
             {Array.isArray(results) && results.length === 0 ? (
               <li><center>Medicamento não encontrado, revise sua busca.</center></li>
             ) : (
               results.map(result => {
                 const adjustedLink = `/html/${result.link}`;
                 return (
                   <li key={result.product_id} className="results-list-item">
                     <div>
                       <h3>{result.titulo}</h3>
                       <p>{result.apresentacao}</p>
                     </div>
                     <div onClick={() => {
    fetchPageContent(adjustedLink);
    setShowingBula(true);
 }}>
    <button className="bula-button">Ver Bula</button>
 </div>
                   </li>
                 );
               })
             )}
           </ul>
         </div>
       )}
     </div>
   );
 }

 export default SearchComponent;
